@font-face {
    font-family: 'Milkshake';
    src: local('Milkshake'), url(./fonts/Milkshake.ttf) format('opentype');
}

@font-face {
    font-family: 'OpenSansRegular';
    font-weight: 700;
    src: local('OpenSansRegular'),
    url(./fonts/OpenSans-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'OpenSansBold';
    font-weight: 900;
    src: local('OpenSansBold'), url(./fonts/OpenSans-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'OpenSansSemiBold';
    font-weight: 600;
    src: local('OpenSansSemiBold'), url(./fonts/OpenSans-SemiBold.ttf) format('opentype');
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./fonts/Inter.ttf) format('opentype');
}

@font-face {
    font-family: 'Inter-Bold';
    font-weight: Bold;
    src: local('Inter'), url(./fonts/Inter.ttf) format('opentype');
}

@font-face {
    font-family: 'Inter-Medium';
    font-weight: 500;
    src: local('Inter'), url(./fonts/Inter.ttf) format('opentype');
}

@font-face {
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('opentype');
}
@font-face {
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    src: local('Montserrat'),url(./fonts/Montserrat-Medium.ttf) format('opentype');
}
@font-face {
    font-family: 'Montserrat-Bold';
    font-weight: 700;
    src: local('Montserrat'),url(./fonts/Montserrat-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    font-weight: 600;
    src: local('Montserrat'),url(./fonts/Montserrat-SemiBold.ttf) format('opentype');
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Montserrat-Regular', 'Montserrat-Medium', 'Helvetica Neue',
    'OpenSansBold', 'OpenSansRegular', 'Milkshake', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
